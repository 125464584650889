/*
 * API Routes
 */

const API_BASE_URL = '/api';

export const defaultOptions = () => ({
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
});

interface createApiRouteOptions {
  withBaseUrl: boolean;
}

/**
 * @param path Path of API route
 * @param options
 * @returns Created API route
 */
const createApiRoute = (
  path: string,
  options: createApiRouteOptions = { withBaseUrl: true }
) => (options.withBaseUrl ? API_BASE_URL + path : path);

export const API_ROUTES = {
  login: {
    url: createApiRoute('/auth/login'),
  },
  logout: {
    url: createApiRoute('/auth/logout'),
  },
  getCurrentUser: {
    url: createApiRoute('/auth/@me'),
  },
  getUserApplications: {
    url: createApiRoute('/@me/applications'),
  },
  getUsers: {
    url(query: string) {
      return createApiRoute(`/users?${query}`);
    },
  },
  getUser: {
    url(id: string) {
      return createApiRoute(`/users/${id}`);
    },
  },
  updateUser: {
    url(id: string) {
      return createApiRoute(`/users/${id}`);
    },
  },
  addRoleToUser: {
    url(user_id: string) {
      return createApiRoute(`/users/${user_id}/roles`);
    },
  },
  deleteRoleFromUser: {
    url(user_id: string, role_id: number) {
      return createApiRoute(`/users/${user_id}/roles/${role_id}`);
    },
  },
  addAdminRoleToUser: {
    url(user_id: string) {
      return createApiRoute(`/users/${user_id}/admin-roles`);
    },
  },
  deleteAdminRoleFromUser: {
    url(user_id: string, role_id: number) {
      return createApiRoute(`/users/${user_id}/admin-roles/${role_id}`);
    },
  },
  activateUserRolePermission: {
    url(user_id: string, user_role_id: number, permission_id: number) {
      return createApiRoute(
        `/users/${user_id}/roles/${user_role_id}/permissions/${permission_id}/activate`
      );
    },
  },
  revokeUserRolePermission: {
    url(user_id: string, user_role_id: number, permission_id: number) {
      return createApiRoute(
        `/users/${user_id}/roles/${user_role_id}/permissions/${permission_id}/revoke`
      );
    },
  },
  getRoles: {
    url: createApiRoute('/roles?with[]=workplace_type'),
  },
  getAdminRoles: {
    url: createApiRoute('/admin-roles?with[]=workplace_type'),
  },
  getWorkplaces: {
    url: createApiRoute('/workplaces'),
  },
  getColleges: {
    url: createApiRoute('/colleges?with[]=parent_type&with[]=parent_id'),
  },
  getCenters: {
    url: createApiRoute('/centers?with[]=parent_type&with[]=parent_id'),
  },
  getDepartments: {
    url: createApiRoute('/departments?with[]=parent_type&with[]=parent_id'),
  },
  getDivisions: {
    url: createApiRoute('/divisions?with[]=parent_type&with[]=parent_id'),
  },
  getUnits: {
    url: createApiRoute('/units?with[]=parent_type&with[]=parent_id'),
  },
} as const;
