import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { ApiService } from '@uomosul/uom-id/api';
import { catchError, map, of, switchMap } from 'rxjs';

export const isLoggedInGuard: CanActivateFn = (route, state) => {
  const apiService = inject(ApiService);
  const router = inject(Router);

  return apiService.fetchCurrentUser().pipe(
    catchError((err) => {
      console.log({ err });

      return of(null);
    }),
    switchMap((user) => (user ? apiService.getUser(user.id) : of(null))),
    map((user) => {
      if (user && user.admin_roles.length > 0) {
        return true;
      } else if (user) {
        window.location.href = '/api/not-found';

        return false;
      } else {
        window.location.href = '/api/auth/login';

        return false;
      }
    })
  );
};
