import { Observable, ReplaySubject, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  ActivateUserRolePermissionResponse,
  AddAdminRoleToUserRequestBody,
  AddAdminRoleToUserResponse,
  AddRoleToUserRequestBody,
  AddRoleToUserResponse,
  DeleteAdminRoleFromUserRequestBody,
  DeleteAdminRoleFromUserResponse,
  DeleteRoleFromUserRequestBody,
  DeleteRoleFromUserResponse,
  GetAdminRolesResponse,
  GetCentersResponse,
  GetCollegesResponse,
  GetCurrentUserResponse,
  GetDepartmentsResponse,
  GetDivisionsResponse,
  GetRolesResponse,
  GetUnitsResponse,
  GetUserApplicationsResponse,
  GetUserResponse,
  GetUsersResponse,
  GetWorkplacesResponse,
  Permission,
  RevokeUserRolePermissionResponse,
  Role,
  UpdateUserRequestBody,
  UpdateUserResponse,
  User,
  UserRole,
} from '@uomosul/uom-id/types';

import { API_ROUTES, defaultOptions } from './api.routes';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  /**
   * Current user subject
   */
  currentUserSubject = new ReplaySubject<User>();

  /**
   * Current user observable
   */
  currentUser$ = this.currentUserSubject.asObservable();

  /**
   * Fetch current user
   * @returns Current user
   */
  fetchCurrentUser(): Observable<User> {
    return this.http.get<GetCurrentUserResponse>(
      API_ROUTES.getCurrentUser.url,
      defaultOptions()
    );
  }

  /**
   * Log out current user
   */
  logout() {
    window.location.href = API_ROUTES.logout.url;
  }

  /**
   * Get list of users
   */
  getUsers(page = 1, search = '') {
    return this.http.get<GetUsersResponse>(
      API_ROUTES.getUsers.url(`page=${page}&search=${search}`)
    );
  }

  /**
   * Get a specific user
   */
  getUser(id: User['id']) {
    return this.http.get<GetUserResponse>(API_ROUTES.getUser.url(id));
  }

  /**
   * Get applications that user has access to
   */
  getUserApplications() {
    return this.http.get<GetUserApplicationsResponse>(
      API_ROUTES.getUserApplications.url,
      defaultOptions()
    );
  }

  updateUser(id: User['id'], requestBody: UpdateUserRequestBody) {
    return this.http.patch<UpdateUserResponse>(
      API_ROUTES.updateUser.url(id),
      requestBody,
      defaultOptions()
    );
  }

  /**
   * Add role to user
   * @param userId Id of user
   * @param requestBody Body of request
   * @returns Updated user roles
   */
  addRoleToUser(userId: User['id'], requestBody: AddRoleToUserRequestBody) {
    return this.http.post<AddRoleToUserResponse>(
      API_ROUTES.addRoleToUser.url(userId),
      requestBody,
      defaultOptions()
    );
  }

  /**
   * Delete role from user
   */
  deleteRoleFromUser(
    userId: User['id'],
    roleId: Role['id'],
    requestBody: DeleteRoleFromUserRequestBody
  ) {
    return this.http.patch<DeleteRoleFromUserResponse>(
      API_ROUTES.deleteRoleFromUser.url(userId, roleId),
      requestBody,
      defaultOptions()
    );
  }

  /**
   * Add admin role to user
   * @param userId Id of user
   * @param requestBody Body of request
   * @returns Updated user roles
   */
  addAdminRoleToUser(
    userId: User['id'],
    requestBody: AddAdminRoleToUserRequestBody
  ) {
    return this.http.post<AddAdminRoleToUserResponse>(
      API_ROUTES.addAdminRoleToUser.url(userId),
      requestBody,
      defaultOptions()
    );
  }

  /**
   * Delete admin role from user
   */
  deleteAdminRoleFromUser(
    userId: User['id'],
    roleId: Role['id'],
    requestBody: DeleteAdminRoleFromUserRequestBody
  ) {
    return this.http.patch<DeleteAdminRoleFromUserResponse>(
      API_ROUTES.deleteAdminRoleFromUser.url(userId, roleId),
      requestBody,
      defaultOptions()
    );
  }

  /**
   * Activate a revoked user's permission
   * @param userId Id of user
   * @param userRoleId Id of user's role
   * @param permissionId Id of permission to activate
   * @returns
   */
  activateUserRolePermission(
    userId: User['id'],
    userRoleId: UserRole['id'],
    permissionId: Permission['id']
  ) {
    return this.http.post<ActivateUserRolePermissionResponse>(
      API_ROUTES.activateUserRolePermission.url(
        userId,
        userRoleId,
        permissionId
      ),
      {},
      defaultOptions()
    );
  }

  /**
   * Revoke a user's permission
   * @param userId Id of user
   * @param userRoleId Id of user's role
   * @param permissionId Id of permission to revoke
   * @returns
   */
  revokeUserRolePermission(
    userId: User['id'],
    userRoleId: UserRole['id'],
    permissionId: Permission['id']
  ) {
    return this.http.post<RevokeUserRolePermissionResponse>(
      API_ROUTES.revokeUserRolePermission.url(userId, userRoleId, permissionId),
      {},
      defaultOptions()
    );
  }

  /**
   * Get all available roles
   */
  getRoles() {
    return this.http.get<GetRolesResponse>(
      API_ROUTES.getRoles.url,
      defaultOptions()
    );
  }

  /**
   * Get all available admin roles
   */
  getAdminRoles() {
    return this.http.get<GetAdminRolesResponse>(
      API_ROUTES.getAdminRoles.url,
      defaultOptions()
    );
  }

  /**
   * Get all workplaces that user can access (Assign roles in)
   */
  getWorkplaces() {
    return this.http.get<GetWorkplacesResponse>(
      API_ROUTES.getWorkplaces.url,
      defaultOptions()
    );
  }

  /**
   * Get all available presidencies
   */
  getPresidencies() {
    return of([{ id: 1, name: 'University Presidency' }]);
  }

  /**
   * Get all available colleges
   */
  getColleges() {
    return this.http.get<GetCollegesResponse>(
      API_ROUTES.getColleges.url,
      defaultOptions()
    );
  }

  /**
   * Get all available centers
   */
  getCenters() {
    return this.http.get<GetCentersResponse>(
      API_ROUTES.getCenters.url,
      defaultOptions()
    );
  }

  /**
   * Get all available departments
   */
  getDepartments() {
    return this.http.get<GetDepartmentsResponse>(
      API_ROUTES.getDepartments.url,
      defaultOptions()
    );
  }

  /**
   * Get all available divisions
   */
  getDivisions() {
    return this.http.get<GetDivisionsResponse>(
      API_ROUTES.getDivisions.url,
      defaultOptions()
    );
  }

  /**
   * Get all available units
   */
  getUnits() {
    return this.http.get<GetUnitsResponse>(
      API_ROUTES.getUnits.url,
      defaultOptions()
    );
  }
}
