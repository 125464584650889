import { tap } from 'rxjs';

import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { ApiService } from '@uomosul/uom-id/api';
import { User } from '@uomosul/uom-id/types';

export const userResolver: ResolveFn<User> = () => {
  const apiService = inject(ApiService);

  return apiService.fetchCurrentUser().pipe(
    tap((currentUser) => {
      apiService.currentUserSubject.next(currentUser);
    })
  );
};
