import { ToastrService } from 'ngx-toastr';
import * as v from 'valibot';

import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';

const ERRORS = {
  UNKNOWN_ERROR: 'حدث خطأ في معالجة الطلب، يرجى المحاولة مرة اخرى.',
  CLIENT_SIDE_ERROR: 'حدث خطأ، يرجى التأكد من الاتصال بالانترنت.',
  SERVER_CONNECTION_ERROR:
    'حدث خطأ خلال الاتصال بالخادم، يرجى المحاولة مرة اخرى',
} as const;

const validationErrorSchema = v.object({
  message: v.string(),
  errors: v.record(v.string(), v.array(v.string())),
});

const genericErrorSchema = v.object({
  message: v.string(),
});

type ValidationError = v.Output<typeof validationErrorSchema>;

interface ParsedError {
  title: string;
  message: string;
  type:
    | 'client_side'
    | 'server_connection_error'
    | 'backend_validation'
    | 'backend_generic'
    | 'unknown';
  timeoutDelay: number;
}

const ERROR_TOASTS_TITLES: { [key in ParsedError['type']]: string } = {
  client_side: 'حدث خطأ!',
  server_connection_error: 'حدث خطأ في الاتصال!',
  backend_validation: 'خطأ في المعلومات المدخلة!',
  backend_generic: 'حدث خطأ!',
  unknown: 'خطأ مجهول',
};

const ERROR_TOASTS_TIMEOUTS: { [key in ParsedError['type']]: number } = {
  client_side: 5000,
  server_connection_error: 3000,
  backend_validation: 0,
  backend_generic: 7500,
  unknown: 3000,
};

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private readonly injector: Injector, private zone: NgZone) {}

  handleError(error: unknown): void {
    const parsedError = parseError(error);

    this.zone.run(() =>
      this.toastr.error(parsedError.message, parsedError.title, {
        timeOut: parsedError.timeoutDelay,
        enableHtml: true,
      })
    );
  }

  /**
   * Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
   * @returns {}
   */
  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }
}

/**
 * Get an informative error message from any error
 * @param error Any error (Trust us!)
 * @returns error message
 */
function parseError(error: unknown): ParsedError {
  if (error instanceof HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred.
      // TODO: (POSSIBLE IMPROVEMENT) Check if user is offline

      console.warn(
        '[UOM-ID] HTTP ERROR HANDLER:',
        'A client-side error occurred:',
        error.error
      );

      return {
        title: ERROR_TOASTS_TITLES['client_side'],
        message: ERRORS.CLIENT_SIDE_ERROR,
        type: 'client_side',
        timeoutDelay: ERROR_TOASTS_TIMEOUTS['client_side'],
      };
    } else if (error.status === 504) {
      // Could not connect to server

      console.warn(
        '[UOM-ID] HTTP ERROR HANDLER:',
        'Failed to connect to server:',
        error.error
      );

      return {
        title: ERROR_TOASTS_TITLES['server_connection_error'],
        message: ERRORS['SERVER_CONNECTION_ERROR'],
        type: 'server_connection_error',
        timeoutDelay: ERROR_TOASTS_TIMEOUTS['server_connection_error'],
      };
    } else {
      // The backend returned an unsuccessful response code, which can be one of two:
      // - A validation error
      // - Everything else

      // Check for validation error

      const validationError = v.safeParse(validationErrorSchema, error.error);

      if (validationError.success === true) {
        // Error is a validation error, Hurray!

        console.warn(
          '[UOM-ID] HTTP ERROR HANDLER:',
          `Backend returned a validation error:`,
          validationError.output
        );

        return {
          title: ERROR_TOASTS_TITLES['backend_validation'],
          message: getValidationErrorMessage(validationError.output),
          type: 'backend_validation',
          timeoutDelay: ERROR_TOASTS_TIMEOUTS['backend_validation'],
        };
      }

      // Check for generic error

      const genericError = v.safeParse(genericErrorSchema, error.error);

      if (genericError.success === true) {
        console.warn(
          '[UOM-ID] HTTP ERROR HANDLER:',
          `Backend returned a generic error:`,
          genericError.output.message
        );

        return {
          title: ERROR_TOASTS_TITLES['backend_generic'],
          message: genericError.output.message,
          type: 'backend_generic',
          timeoutDelay: ERROR_TOASTS_TIMEOUTS['backend_generic'],
        };
      }
    }
  }

  console.warn('[UOM-ID] ERROR HANDLER:', 'Unknown error occurred:', error);

  // If we don't know what to do, we display a generic error message
  return {
    title: ERROR_TOASTS_TITLES['unknown'],
    message: ERRORS.UNKNOWN_ERROR,
    type: 'unknown',
    timeoutDelay: ERROR_TOASTS_TIMEOUTS['unknown'],
  };
}

function getValidationErrorMessage(validationError: ValidationError): string {
  if (Object.keys(validationError.errors).length === 0) {
    return validationError.message;
  } else {
    return (
      Object.values(validationError.errors)
        // TODO: Decide whether it's best to only pick the first validation error
        .map((errorMessages) => `● ${errorMessages[0]}`)
        .join('</br>')
    );
  }
}
