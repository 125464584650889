import { Route } from '@angular/router';
import { isLoggedInGuard } from './is-logged-in.guard';
import { userResolver } from './user.resolver';

export const appRoutes: Route[] = [
  {
    path: '',
    loadComponent: () =>
      import('./home/home.component').then((m) => m.HomeComponent),
    canActivate: [isLoggedInGuard],
    resolve: {
      currentUser: userResolver,
    },
  },
  {
    path: 'users/:id',
    loadComponent: () =>
      import('./users/user-detail/user-detail.component').then(
        (m) => m.UserDetailComponent
      ),
    canActivate: [isLoggedInGuard],
    resolve: {
      currentUser: userResolver,
    },
  },
];
